<template>
	<div id="container" class="container"></div>
</template>

<script>
	import * as THREE from 'three'
	
	export default {
		name: 'home',
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
				
				camera: null,
				scene: null,
				renderer: null,
				
				r: 450,
				mouseY: 0,
				windowHalfY: window.innerHeight / 2,
			}
		},
		mounted() {
			this.init()
			this.animate()
		},
		methods: {
			init() {
				this.camera = new THREE.PerspectiveCamera(80, this.width / this.height, 1, 3000)
				this.camera.position.z = 1000

				this.scene = new THREE.Scene()

				const parameters = [
					[0.25, 0xff7700, 1],
					[0.5, 0xff9900, 1],
					[0.75, 0xffaa00, 0.75],
					[1, 0xffaa00, 0.5],
					[1.25, 0x000833, 0.8],
					[3.0, 0xaaaaaa, 0.75],
					[3.5, 0xffffff, 0.5],
					[4.5, 0xffffff, 0.25],
					[5.5, 0xffffff, 0.125]
					
					// [1.25, 0x000833, 0.8],
					// [3.0, 0xaaaaaa, 0.75],
					// [3.5, 0xff9900, 0.5],
					// [4.5, 0xffaa00, 0.25],
					// [5.5, 0xffffff, 0.125]
				]

				const geometry = this.createGeometry()

				for (let i = 0; i < parameters.length; ++i) {
					const p = parameters[i]
					const material = new THREE.LineBasicMaterial({
						color: p[1],
						opacity: p[2]
					})

					const line = new THREE.LineSegments(geometry, material)
					line.scale.x = line.scale.y = line.scale.z = p[0]
					line.userData.originalScale = p[0]
					line.rotation.y = Math.random() * Math.PI
					line.updateMatrix()
					this.scene.add(line)
				}

				this.renderer = new THREE.WebGLRenderer({
					antialias: true
				})
				this.renderer.setPixelRatio(window.devicePixelRatio)
				this.renderer.setSize(this.width, this.height)
				
				const el = document.getElementById('container')
				el.appendChild(this.renderer.domElement)

				el.style.touchAction = 'none'
				el.addEventListener('pointermove', this.onPointerMove)

				window.addEventListener('resize', this.onWindowResize)

				// test geometry swapability
				// setInterval(function() {
				// 	const geometry = createGeometry()

				// 	this.scene.traverse(function(object) {
				// 		if (object.isLine) {
				// 			object.geometry.dispose()
				// 			object.geometry = geometry
				// 		}
				// 	})
				// }, 1000)
			},

			createGeometry() {
				const geometry = new THREE.BufferGeometry()
				const vertices = []
				const vertex = new THREE.Vector3()

				// for (let i = 0; i < 1500; i++) {
				for (let i = 0; i < 30; i++) {
					vertex.x = Math.random() * 2 - 1
					vertex.y = Math.random() * 2 - 1
					vertex.z = Math.random() * 2 - 1
					vertex.normalize()
					vertex.multiplyScalar(this.r)

					vertices.push(vertex.x, vertex.y, vertex.z)

					vertex.multiplyScalar(Math.random() * 0.09 + 1)

					vertices.push(vertex.x, vertex.y, vertex.z)

				}

				geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
				return geometry
			},

			onWindowResize() {
				this.windowHalfY = window.innerHeight / 2

				this.camera.aspect = window.innerWidth / window.innerHeight
				this.camera.updateProjectionMatrix()

				this.renderer.setSize(window.innerWidth, window.innerHeight)
			},

			onPointerMove(event) {
				console.log("onPointerMove: ", event)
				
				if (event.isPrimary === false) return

				this.mouseY = event.clientY - this.windowHalfY
				console.log("mouseY: ", this.mouseY)
			},

			//
			animate() {
				requestAnimationFrame(this.animate)
				this.render()
			},

			render() {
				this.camera.position.y += (-this.mouseY + 200 - this.camera.position.y) * .05
				this.camera.lookAt(this.scene.position)
				this.renderer.render(this.scene, this.camera)

				const time = Date.now() * 0.0001
				for (let i = 0; i < this.scene.children.length; i++) {
					const object = this.scene.children[i]
					if (object.isLine) {
						object.rotation.y = time * (i < 4 ? (i + 1) : -(i + 1))

						if (i < 5) {
							const scale = object.userData.originalScale * (i / 5 + 1) * (1 + 0.5 * Math.sin(7 * time))
							object.scale.x = object.scale.y = object.scale.z = scale
						}
					}
				}
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		height: 100%;
	}
</style>
